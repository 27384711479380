import { AccountType, BuisnessType } from 'src/app/models/loans';
import { CommonActions, GET_ACCOUNT_TYPES, GET_ACCOUNT_TYPES_FAILURE, GET_ACCOUNT_TYPES_SUCCESS, GET_APP_CONFIG, GET_APP_CONFIG_FAILURE, GET_APP_CONFIG_SUCCESS, GET_BUISNESS_TYPES, GET_BUISNESS_TYPES_FAILURE, GET_BUISNESS_TYPES_SUCCESS } from './common.actions';
import { DataState } from '../common-types';
import { AppConfigState } from '../app.states';

export interface CommonState {
    buisnessTypes: DataState<BuisnessType[]>,
    accountTypes: DataState<AccountType[]>,
    appConfig: AppConfigState,
};

const initialCommonState: CommonState = {
    buisnessTypes: { data: [], errorMessage: undefined },
    accountTypes: { data: [], errorMessage: undefined },
    appConfig: { timeAutoSave: 0 }
}

export function commonReducer(state = initialCommonState, action: CommonActions): CommonState {
    switch (action.type) {
        case GET_ACCOUNT_TYPES:
        case GET_BUISNESS_TYPES: {
            return { ...state }
        }
        case GET_BUISNESS_TYPES_SUCCESS: {
            return {
                ...state,
                buisnessTypes: {
                    errorMessage: undefined,
                    data: action.data
                }
            }
        }
        case GET_BUISNESS_TYPES_FAILURE: {
            return {
                ...state,
                buisnessTypes: {
                    errorMessage: action.message,
                    data: []
                }
            }
        }
        case GET_APP_CONFIG: {
            return { ...state };
        }
        case GET_APP_CONFIG_SUCCESS: {
            return { ...state, appConfig: action.appConfig };
        }
        case GET_APP_CONFIG_FAILURE: {
            return { ...state };
        }

        case GET_ACCOUNT_TYPES_SUCCESS: {
            return {
                ...state,
                accountTypes: {
                    errorMessage: undefined,
                    data: action.data
                }
            }
        }

        case GET_ACCOUNT_TYPES_FAILURE: {
            return {
                ...state,
                accountTypes: {
                    errorMessage: action.message,
                    data: []
                }
            }
        }

        default:
            return { ...state }
    }
}