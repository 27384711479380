import { Injectable } from '@angular/core';
import { CommonService } from 'src/app/service/CommonService/common.service';
import { GetAccountTypes, GetAccountTypesFailure, GetAccountTypesSuccess, GetBuisnessTypes, GetBuisnessTypesFailure, GetBuisnessTypesSuccess } from './common.actions';
import { Actions, ofType, createEffect } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, map, mergeMap } from 'rxjs/operators';
import { GetAppConfig, GetAppConfigFailure, GetAppConfigSuccess } from './common.actions';


@Injectable()
export class CommonEffects {

  constructor(
    private actions$: Actions,
    private commonService: CommonService
  ) { };

  GetBuisnessTypes = createEffect(() =>
    this.actions$.pipe(
      ofType(GetBuisnessTypes),
      mergeMap(() => {
        return this.commonService.getBuisnessTypes().pipe(
          map(response => {
            if (response.success) {
              return GetBuisnessTypesSuccess({ data: response.data })
            } else {
              return GetBuisnessTypesFailure({ errors: response.errors, message: response.message })
            }
          }),
          catchError(error => of(GetBuisnessTypesFailure({ message: error })))
        )
      })
    )
  )


  GetAppConfig = createEffect(() =>
    this.actions$.pipe(
      ofType(GetAppConfig),
      mergeMap(action => {
        return this.commonService.GetAppConfig().pipe(

          map(res => {
            if (res != null)
              return GetAppConfigSuccess({ appConfig: res });
            else
              return GetAppConfigFailure({ error: "" });
          }),
          catchError(error => of(GetAppConfigFailure({ error })))

        );
      })
    )
  );

  GetAccountTypes = createEffect(() =>
    this.actions$.pipe(
      ofType(GetAccountTypes),
      mergeMap(action => {
        return this.commonService.getAccountTypes().pipe(
          map(response => {
            if (response.success) {
              return GetAccountTypesSuccess({ data: response.data })
            } else {
              return GetAccountTypesFailure({ errors: response.errors, message: response.message })
            }
          }),
          catchError(error => of(GetAccountTypesFailure({ message: error })))
        )
      })
    ))
}