import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { RuleEngineService } from "src/app/service/RuleEngineService/rule-engine.service";
import { AddRule, GetAddedRuleSuccess, getLOSFieldList, getLOSFieldListSuccess, GetRuleById, GetRuleByIdSuccess, GetUpdatedRuleSuccess, UpdateRule } from "./rule-engine.actions";
import { map, mergeMap } from "rxjs";

@Injectable()
export class RuleEngineEffects {
    constructor(
        private actions$: Actions,
        private ruleEngineService: RuleEngineService,
    ) { };

    AddRule$ = createEffect(() =>
        this.actions$.pipe(
            ofType(AddRule),
            mergeMap(action =>
                this.ruleEngineService.createRule(action.rule).pipe(
                    map(response => {
                        return GetAddedRuleSuccess({ rule: response })
                    })
                )
            )
        )
    )

    UpdateRule$ = createEffect(() =>
        this.actions$.pipe(
            ofType(UpdateRule),
            mergeMap(action =>
                this.ruleEngineService.updateRule(action.ruleId, action.rule).pipe(
                    map(response => {
                        return GetUpdatedRuleSuccess({ rule: response })
                    })
                )
            )
        )
    )

    GetRuleById$ = createEffect(() =>
        this.actions$.pipe(
            ofType(GetRuleById),
            mergeMap(action =>
                this.ruleEngineService.getRuleById(action.ruleId).pipe(
                    map(response => {
                        return GetRuleByIdSuccess({ rules: response.rules })
                    })
                )
            )
        )
    )


    GetLOSFieldList$ = createEffect(() =>
        this.actions$.pipe(
            ofType(getLOSFieldList),
            mergeMap(action =>
                this.ruleEngineService.getLosFieldList().pipe(
                    map(response => {
                        if (response.success) {
                            return getLOSFieldListSuccess({ LOSFieldList: response.data });
                        } else {
                            return getLOSFieldListSuccess({ LOSFieldList: [] });
                        }
                    })
                )
            )
        )
    )
}