import { SummaryActions, GET_DOCUMENT_BY_STATEMENT_ID, GET_DOCUMENT_BY_STATEMENT_ID_SUCCESS, GET_DOCUMENT_BY_STATEMENT_ID_FAILURE } from './summary.actions';
import { StatementSummaryState } from './summary.types';

const initialState: StatementSummaryState = {
    documents: { errorMessage: undefined, data: {}, loading: false }
};

export function summaryReducer(state = initialState, action: SummaryActions): StatementSummaryState {
    switch (action.type) {
        case GET_DOCUMENT_BY_STATEMENT_ID:
            return {
                ...state,
                documents: {
                    ...state.documents,
                    loading: true
                }
            };
        case GET_DOCUMENT_BY_STATEMENT_ID_SUCCESS:
            return {
                ...state,
                documents: {
                    errorMessage: undefined,
                    data: {
                        ...state.documents.data,
                        [action.statementId]: action.data
                    },
                    loading: false
                }
            };

        case GET_DOCUMENT_BY_STATEMENT_ID_FAILURE:
            const { [action.statementId]: removedDocument, ...remainingDocuments } = state.documents.data;
            return {
                ...state,
                documents: {
                    errorMessage: action.message,
                    data: remainingDocuments,
                    loading: false
                }
            };

        default:
            return state;
    }
}
