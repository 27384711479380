import { Component, Inject, OnDestroy, OnInit } from "@angular/core";
import { MSAL_GUARD_CONFIG, MsalBroadcastService, MsalGuardConfiguration, MsalService } from "@azure/msal-angular";
import {
  AuthenticationResult,
  InteractionStatus,
  InteractionType,
  PopupRequest,
  RedirectRequest,
} from "@azure/msal-browser";
import { BnNgIdleService } from "bn-ng-idle";
import { filter, Subject, takeUntil } from "rxjs";
import { TokenStorageService } from "./service/TokenStorageService/token-storage.service";

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"],
})
export class AppComponent implements OnInit, OnDestroy {
  title = "MSuit";
  isIframe = false;
  isSessionExpired = false;
  isLoggedIn = false;

  private readonly destroy$ = new Subject<void>();

  constructor(
    private idleService: BnNgIdleService,
    @Inject(MSAL_GUARD_CONFIG) private msalConfig: MsalGuardConfiguration,
    private msalService: MsalService,
    private msalBroadcastService: MsalBroadcastService,
    private tokenStorageService: TokenStorageService
  ) {
    this.idleService.startWatching(1200).subscribe((isExpired) => {
      if (isExpired) {
        console.log("Session expired");
        this.isSessionExpired = true;
        this.tokenStorageService.signOut();
      }
    });
  }

  ngOnInit(): void {
    localStorage.removeItem("isTransactionReviewScreenActive");
    this.isIframe = window !== window.parent && !window.opener;

    this.msalBroadcastService.inProgress$
      .pipe(
        filter((status) => status === InteractionStatus.None),
        takeUntil(this.destroy$)
      )
      .subscribe(() => {
        this.updateLoginStatus();
        this.ensureActiveAccount();
      });
  }

  updateLoginStatus(): void {
    this.isLoggedIn = this.msalService.instance.getAllAccounts().length > 0;
  }

  ensureActiveAccount(): void {
    const activeAccount = this.msalService.instance.getActiveAccount();

    if (!activeAccount && this.msalService.instance.getAllAccounts().length > 0) {
      const accounts = this.msalService.instance.getAllAccounts();
      const firstAccount = accounts[0];
      this.msalService.instance.setActiveAccount(firstAccount);
      localStorage.setItem("AuthLoginData", JSON.stringify(firstAccount));

      console.log("ID Token:", firstAccount.idToken);
      console.log("ID Token Claims:", firstAccount.idTokenClaims);
    }
  }

  login(redirectRequest?: RedirectRequest | PopupRequest): void {
    const interactionType = this.msalConfig.interactionType;
    const authRequest = this.msalConfig.authRequest;

    if (interactionType === InteractionType.Popup) {
      this.msalService
        .loginPopup({ ...authRequest, ...redirectRequest } as PopupRequest)
        .subscribe((response: AuthenticationResult) => {
          this.msalService.instance.setActiveAccount(response.account);
        });
    } else {
      this.msalService.loginRedirect({ ...authRequest, ...redirectRequest } as RedirectRequest);
    }
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
