
import { TransactionActions, GET_TRANSACTION_DETAILS, GET_TRANSACTION_DETAILS_FAILURE, GET_TRANSACTION_DETAILS_SUCCESS, GET_TRANSACTION_STATEMENT_PERIOD_DETAILS, GET_TRANSACTION_STATEMENT_PERIOD_DETAILS_FAILURE, GET_TRANSACTION_STATEMENT_PERIOD_DETAILS_SUCCESS, SAVE_VALIDATE_STATEMENT_TRANSACTION, REVALIDATE_SUMMARY, REVALIDATE_SUMMARY_FAILURE, REVALIDATE_SUMMARY_SUCCESS, PROCESS_TRANSACTION_FLAG, PROCESS_TRANSACTION_FLAG_SUCCESS, PROCESS_TRANSACTION_FLAG_FAILURE, PROCESS_CATEGORIES, PROCESS_CATEGORIES_SUCCESS, PROCESS_CATEGORIES_FAILURE, CLEAR_SAVE_VALIDATE_STATEMENT_TRANSACTION, SAVE_VALIDATE_STATEMENT_TRANSACTION_SUCCESS, SAVE_VALIDATE_STATEMENT_TRANSACTION_FAILURE, RESET_TRANSACTIONSFLAG_INFO, RESET_TRANSACTIONSCATEGORY_INFO } from "./transaction.actions";
import { StatementTransactionState } from "./transaction.types";

const initialState: StatementTransactionState = {
    transactionDetail: { data: [], errorMessage: undefined },
    transactionStatementPeriodDetails: { data: [], errorMessage: undefined },
    reValidateStatement: { data: '', errorMessage: undefined },
    processTransactionFlag: { data: undefined, errorMessage: undefined },
    processCategories: { data: undefined, errorMessage: undefined },
    saveOrValidateTransaction: { data: [], errorMessage: undefined }
}

export function transactionReducer(state = initialState, action: TransactionActions): StatementTransactionState {
    switch (action.type) {
        case GET_TRANSACTION_DETAILS:
        case REVALIDATE_SUMMARY:
        case PROCESS_TRANSACTION_FLAG:
        case PROCESS_CATEGORIES:
            return state;

        case GET_TRANSACTION_DETAILS_SUCCESS: {
            return {
                ...state,
                transactionDetail: {
                    errorMessage: undefined,
                    data: action.data
                }
            }
        }
        case GET_TRANSACTION_DETAILS_FAILURE: {
            return {
                ...state,
                transactionDetail: {
                    errorMessage: action.message,
                    data: []
                }
            }
        }
        case GET_TRANSACTION_STATEMENT_PERIOD_DETAILS_SUCCESS: {
            return {
                ...state,
                transactionStatementPeriodDetails: {
                    errorMessage: undefined,
                    data: action.data
                }
            }
        }
        case GET_TRANSACTION_STATEMENT_PERIOD_DETAILS_FAILURE: {
            return {
                ...state,
                transactionStatementPeriodDetails: {
                    errorMessage: action.message,
                    data: []
                }
            }
        }

        case SAVE_VALIDATE_STATEMENT_TRANSACTION_SUCCESS: {
            return {
                ...state,
                saveOrValidateTransaction: {
                    errorMessage: undefined,
                    data: action.data
                }
            }
        }

        case SAVE_VALIDATE_STATEMENT_TRANSACTION_FAILURE: {
            return {
                ...state,
                saveOrValidateTransaction: {
                    errorMessage: action.message,
                    data: []
                }
            }
        }

        case CLEAR_SAVE_VALIDATE_STATEMENT_TRANSACTION: {
            return {
                ...state,
                saveOrValidateTransaction: {
                    errorMessage: undefined,
                    data: []
                }
            }
        }

        case REVALIDATE_SUMMARY_SUCCESS: {
            return {
                ...state,
                reValidateStatement: {
                    data: action.message,
                    errorMessage: undefined
                }
            }
        }
        case REVALIDATE_SUMMARY_FAILURE: {
            return {
                ...state,
                reValidateStatement: {
                    data: '',
                    errorMessage: action.message
                }
            }
        }
        case PROCESS_TRANSACTION_FLAG_SUCCESS: {
            return {
                ...state,
                processTransactionFlag: {
                    data: 'success',
                    errorMessage: undefined
                }
            }
        }

        case RESET_TRANSACTIONSFLAG_INFO:{
            return {
                ...state,
                processTransactionFlag: {
                    data: undefined,
                    errorMessage: undefined
                }
            }
        }
        case RESET_TRANSACTIONSCATEGORY_INFO:{
            return {
                ...state,
                processCategories: {
                    data: undefined,
                    errorMessage: undefined
                }
            }
        }


        case PROCESS_TRANSACTION_FLAG_FAILURE: {
            return {
                ...state,
                processTransactionFlag: {
                    data: undefined,
                    errorMessage: action.message
                }
            }
        }
        case PROCESS_CATEGORIES_SUCCESS: {
            return {
                ...state,
                processCategories: {
                    data: 'success',
                    errorMessage: undefined
                }
            }
        }
        case PROCESS_CATEGORIES_FAILURE: {
            return {
                ...state,
                processCategories: {
                    data: 'success',
                    errorMessage: action.message
                }
            }
        }
        default:
            return state;
    }
};