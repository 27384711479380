<router-outlet>
    <footer class="footer-main">
        <div class="container-fluid">
            <div class="row">
                <div class="col-6">
                    <span class="me-1">© Copyright</span>
                    <img src="../../../assets/images/fws-logo.png" class="img-fluid" alt="Flatworld Solutions">
                </div>
                <div class="col-6 text-end">
                  <span class="text-right me-1">Powered by</span>
                    <img src="../../../assets/images/logo-rev.png" class="img-fluid" alt="Revelaition">
                </div>
            </div>
        </div>
      </footer>
</router-outlet>