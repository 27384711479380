import { Injectable } from '@angular/core';
import { Actions, ofType, createEffect } from '@ngrx/effects';
import { catchError, map, mergeMap } from 'rxjs/operators';
import { of } from 'rxjs';
import { IncomeCalculationServiceService } from 'src/app/service/IncomeCalculation/income-calculation.service';
import { GetExportLoan, GetExportLoanSuccess, GetExportLoanFailure } from './incomeCalculation.actions';

@Injectable()
export class IncomeCalculationEffects {
    constructor(
        private actions$: Actions,
        private incomeCalculationService: IncomeCalculationServiceService,
    ) { }

    GetExportLoan$ = createEffect(() =>
        this.actions$.pipe(
            ofType(GetExportLoan),
            mergeMap((action) =>
                this.incomeCalculationService.exportToExcel(action.LoanId).pipe(
                    map(response => {
                        if (response.success) {
                            return GetExportLoanSuccess({ data: response.data });
                        } else {
                            return GetExportLoanFailure({ errors: response.errors, message: response.message })
                        }
                    }),
                    catchError(error => of(GetExportLoanFailure({ message: error })))
                )
            )
        ));
}