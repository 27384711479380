import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, map, mergeMap, withLatestFrom } from 'rxjs/operators';
import { GetDocumentByStatementId, GetDocumentByStatementIdFailure, GetDocumentByStatementIdSuccess } from "./summary.actions";
import { BankStatementExtractionService } from 'src/app/service/BankStatementExtractionService/bank-statement-extraction.service';
import { Store } from '@ngrx/store';
import { selectDocumentByStatementIdSelector } from './summary.selectors';

@Injectable()
export class SummaryEffects {
    constructor(
        private actions$: Actions,
        private extractionService: BankStatementExtractionService,
        private store: Store
    ) { };
    
    GetDocumentByStatementId$ = createEffect(() =>
        this.actions$.pipe(
            ofType(GetDocumentByStatementId),
            mergeMap(action =>
                this.store.select(selectDocumentByStatementIdSelector(action.statementId)).pipe(
                    mergeMap(existingDocument => {
                        if (existingDocument && !action.freshFetch) {
                            return of(GetDocumentByStatementIdSuccess({ statementId: action.statementId, data: existingDocument }));
                        } else {
                            return this.extractionService.getStatementSummaryFile(action.statementId).pipe(
                                map(response => {
                                    if (response.success) {
                                        return GetDocumentByStatementIdSuccess({ data: response.data, statementId: action.statementId });
                                    } else {
                                        return GetDocumentByStatementIdFailure({ errors: response.errors, message: response.message, statementId: action.statementId });
                                    }
                                }),
                                catchError(error => of(GetDocumentByStatementIdFailure({ message: error, statementId: action.statementId })))
                            );
                        }
                    })
                )
            )
        )
    );
}